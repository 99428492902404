<template>
  <div class="sponsor" v-if="sponsorList.length > 0">
    <h2>赞助商</h2>
    <ul class="list">
      <li v-for="(item, index) in sponsorList" :key="'live_sponsor_'+index">
        <img :src="item.file" alt="" class="item" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sponsorList: [],
    };
  },
  mounted() {
    this.getSponsorList();
  },
  methods: {
    getSponsorList: function () {
      const url = "https://api.msup.com.cn/sue/get-all-sue-info/256";
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log(res);
          if (res.errno == 0) {
            this.sponsorList = res.data[0];
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .sponsor {
    margin-top: 27px;
    padding: 27px 19px 15px 22px;
    width: 305px;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    h2 {
      margin-bottom: 20px;
      border-left: 4px solid #0a70c5;
      padding-left: 10px;
      color: #161616;
      font-size: 19px;
      font-weight: 400;
      text-align: left;
    }
    .list {
      display: flex;
      flex-direction: column;
      li {
        margin-bottom: 20px;
        img {
          width: 264px;
          height: 100px;
          border-radius: 12px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .sponsor {
    width: 100%;
    padding-top: 14px;
    padding-bottom: 20px;
    h2 {
      display: none;
    }
    .list {
      display: flex;
      flex-direction: column;
      li {
        margin-bottom: 8px;
        text-align: center;
        img {
          width: 67vw;
          border-radius: 12px;
        }
      }
    }
  }
}
</style>
